import React from "react"
const Video = ({ videoSrcURL, videoTitle, width, height }) => (
  <div className="video">
    <iframe
      src={videoSrcURL}
      title={videoTitle}
      width={width}
      height={height}
      allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture; fullscreen"
      frameBorder="0"
      webkitallowfullscreen="true"
      mozallowfullscreen="true"
      allowFullScreen
    />
  </div>
)
export default Video;